/***
Custom icon buttons
***/

.icon-btn {
    height: 60px;
    min-width: 80px;
    margin: 5px 5px 0 0;
    border: 1px solid #ddd;
    padding: 12px 0px 0px 0px;
    background-color: #fafafa;
    background-image: none;
    filter:none;
    
    @if $theme-type == "default" {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    
    display:inline-block;
    color: #646464;
    text-shadow: none;
    text-align: center;
    cursor: pointer;
    position: relative;  
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &:hover {
        text-decoration: none;
        border-color: #999;
        color: #444;
        text-shadow: 0 1px 0px rgba(255, 255, 255, 1);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;

        @if $theme-type == "default" {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }

        > .badge {
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;

           @if $theme-type == "default" {
           -webkit-box-shadow: none;
               -moz-box-shadow: none;
                    box-shadow: none;
           }
        }
    }

    > div {
        margin-top: 5px;
        margin-bottom: 20px;  
        color: $general-panel-font-color;
        font-size: 12px;
        font-weight: 300;
    }

    > .badge {
        position: absolute;
        font-size: 11px;
        font-weight: 300;
        top: -5px;
        right: -5px;
        padding: 3px 6px 3px 6px;
        color: white;
        text-shadow: none;
        border-width: 0;
        border-style: solid;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;

        @if $theme-type == "default" {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
                 box-shadow: none;
        }
    }

    > i {
        font-size: 18px;
    }

    .ie8 &:hover {
        filter: none;    
    }
}

@if $theme-type == "material-design" {  
  .icon-btn { 
    border: 0;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    
    > div {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 11px;
        color: lighten($general-panel-font-color, 10%);
    }

    @extend .md-shadow-z-1;  

    &:hover {
        text-decoration: none;
        @extend .md-shadow-z-2-hover; 
    }
  }
}