// CONTACT PAGE

.c-content-contact-1 {
	width: 100%;
	position: relative;
	margin-bottom: 70px;

	&:before{
		background-color:rgba(255,255,255,0.5);
	}

	&:after{
		background-color:rgba(255,255,255,0.5);	
	}

	> .row {
			
		.c-body {
			position: relative;
			z-index: 100;
			background: #ffffff;
			margin: 40px 40px 40px 0;
			padding:60px 40px;
 
			> .c-section {
				margin-bottom: 15px;
				text-align: right;

				>.c-content-label{
					display:inline;
					padding:3px 7px;
					color:#fff;
				}

				> h3 {
					font-size:26px;
					margin:0 0 30px 0;
					text-transform: uppercase; 
				}

				> p {
					font-size:16px;
					line-height:24px;
					color:#7f8c97;
					font-weight: 300;
				}				

				> p,
				> .c-content-iconlist-1 {
					margin-top: 20px;
					>li{
						list-style: none;
						display: inline-block;
						font-size: 24px;
						padding:0 10px 5px 10px;
						background-color: #eee;
					}
				}
			}
		}
	}
}

// FULL WIDTH GMAPS CONTROLLER
.c-content-contact-1-gmap {
	display: block;
	top:0;
	bottom:0;
	left:0;
	right:0;
	position: absolute;
	width: 100%;
}

.c-content-feedback-1 {
	width: 100%;

	> .row{

		> div {

			.c-content-title-1{ 

				> h3{
					margin-top: 10px;
					margin-bottom: 20px;
				}

				> .c-line-left{
					margin-bottom:30px;
					height:3px;
					width:40px;
				}

				> p {
					margin-bottom: 30px;
					font-weight: 300;
				}
			}
 
			> .c-container{
				background-size:auto;
				padding:30px;
				margin: 0 0 30px 0;

				> .c-inverse{

					> h3{
						color:#ffffff;
						margin-top:10px;
					}

					> .c-line-left{
						background-color: #ffffff;
					}

					> p{
						color: #ffffff;
					}
				}

				&:last-child{
					margin-bottom:0;

					p {
						margin-bottom: 0;
					}  
				}
			}

			> .c-contact{
				
			}

			form {
				margin-bottom: 20px;

				.input-group {
					width: 340px;
				}
			}	
		}
	}

	.input-group {
		border: 0;  

		.input-group-addon,
		.btn {
			border: 0;
		}
	}
  
}



// BEGIN: Mobile Mode
@media (max-width:1024px){

	.c-content-feedback-1 {

		>.row{

			> div{

				form {
					.input-group {
						width: 100%;
					}
				}
			}
		}
	}

}
   
@media (max-width: 991px) { /* 991px */

	.c-content-contact-1-gmap { 
		display: block;
		position: static;
		width: 100%;
		height: 300px !important;
	}

	.c-content-contact-1 {

		> .row{

			> div {
				width:100%;

				.c-body {
					position: static;
					display: block;
					padding: 30px 20px 10px 20px; 
					background: #fafafa;
					margin: 0;
					text-align:left;

					//@include clearfix();
				}

			}

		}  
		
	}

	.c-content-feedback-1 {

		>.row{

			> div{

				>.c-container{
					margin-right:0; 
					margin-bottom:40px;
				}

				> .c-contact{
					padding:40px 30px 0 30px;
					margin:0;
				}

			}
		}

		&.c-option-2{

			> .row{

				> div{

					> .c-contact{
						padding: 30px;
						margin-top:40px;
						background-color: #ffffff;
					}
				}
			}
		}
	}
}

// END