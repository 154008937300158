@import "_utils.scss";
@import "_spinner.scss";
@import "_layout.scss";


/**
    DROPZONE.js
 */

.dropzone-icon{
  width:74px;
  height:86px;
  background:url(#{$global-img-path}dropzone-icon.png) no-repeat 0 0;
  margin:10px auto;
}
.dropzone-icon.dragenter{
  background:url(#{$global-img-path}dropzone-icon.png) no-repeat 0 100%;
}
#cropimage{
  max-width: 100%;
}
.cropWarning {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.9;
  display: none;
}
img.lazyloading {
  width:100%;
  min-height:300px;
  background:url(#{$global-img-path}loading-spinner-blue.gif) no-repeat 50% 50%;
}
.squareBackground {
  background-image:
    -moz-linear-gradient(45deg, #f1f1f1 25%, transparent 25%),
    -moz-linear-gradient(-45deg, #f1f1f1 25%, transparent 25%),
    -moz-linear-gradient(45deg, transparent 75%, #f1f1f1 75%),
    -moz-linear-gradient(-45deg, transparent 75%, #f1f1f1 75%);
  background-image:
      -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, #f1f1f1), color-stop(.25, transparent)),
      -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.25, #f1f1f1), color-stop(.25, transparent)),
      -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.75, transparent), color-stop(.75, #f1f1f1)),
      -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.75, transparent), color-stop(.75, #f1f1f1));

  -moz-background-size:100px 100px;
  background-size:100px 100px;
  -webkit-background-size:100px 101px; /* override value for shitty webkit */

  background-position:0 0, 50px 0, 50px -50px, 0px 50px;
}

/**
    DATATABLES
 */
div.dataTables_wrapper {

  .dataTables_info {
    float: none;
  }

  div.dataTables_paginate{
    text-align:center;
    float:none;
  }
}
.table>tfoot>tr>td {
  padding: 8px;
}

.table>tbody>tr.hiddenRow{
  > td{
    padding: 0;
    .accordian-body{
      padding: 8px;
    }
  }

}

/**
    CARDS e PROFILO
 */
.cards {
  a:hover,
  a.active {

    text-decoration: none !important;

    span {
      text-decoration: none !important;
    }
    div {
      text-decoration: none !important;
    }
    .card-icon {
      i {
        background:#32c5d2;
        color:#FFF !important;
      }
    }
  }
}

.page-content {
  .profile-sidebar {
    float: none;
    width: auto;
    margin-right: 0;
  }
}
.profile-sidebar-portlet {
  padding: 0 !important;
}

/**
    PORTLET e BLOCCHI
 */

.portlet {

  .portlet-body {
    position:relative;

    .repeat-element {

      border:2px dashed #CCC;
      padding-top:15px;
      position:relative;

      .actions {
        position: absolute;
        z-index: 100;
        right: -32px;
        top: 6px;

        a {
          display: block;
          background-color: #fafafa;
          margin-bottom: 5px;
        }
      }
    }
  }

  .block-settings {
    display: none;
    background-color: rgba(53, 152, 220, 0.9);
    position: absolute;
    top: 0;
    left: 0
  }
  &.portlet-config {
    .block-settings {
      display:block;
      z-index: 100;
      label {
        color:#FFF;
      }
      .help-block{
        color:#FFF;
      }
    }
  }
}

#block-list-filters {

  .cbp-filter-item {
    line-height: 18px;
    padding: 10px !important;
  }
}
.field-wrapper-middle {
  display: table;
}
.field-wrapper-middle span {
  display: table-cell;
  vertical-align: middle;
}

/**
    SORTABLE
 */

.sortable_list {

  .list-todo-mid {
    border-top: 1px solid #e7ecf1;
    .list-todo-item {
      width: 70% !important;
    }

    ul{
      li{
        &.mt-list-item-placeholder {

          border: 1px dashed #e7ecf1;
          margin-bottom: 0px;
          height: 80px;
          list-style: none;
        }
      }
    }
  }

  ul {

    .mt-list-item-empty {
      box-shadow: none !important;
      height: 50px;
    }

    li {
      cursor: move;

      .list-item-content {

        padding: 0 60px 0 30px !important;

      }

      &.mt-list-item-placeholder {

        border: 2px dashed #eee;
        margin-bottom: 0px;
        height: 50px;
        list-style: none;
      }
    }
  }
  .list-simple {
    &.mt-list-head {
      .list-title {
        padding: 0;
      }
    }
  }
}
@mixin sortable-placeholder {
  border: 2px dashed #eee;
  visibility: visible !important;
  display:block !important;
  box-shadow: none !important;
}

.element-sortable {

  max-width:200px;
  float:left;
  padding:10px;

  .mt-overlay {
    cursor: move;
  }

  &.ui-sortable-helper {
    @include sortable-placeholder;
  }
}

#gallery {
  #gallery-elements {
    .element-sortable {
      &.ui-sortable-placeholder {
        @include sortable-placeholder;
      }
    }
  }
}

/**
    PAGE MODEL
 */

#pages-tree {
  overflow: hidden;
}
#content_model .form-group.form-md-line-input {
  margin: 0 0 10px 0;
}
#gallery #gallery-captions {
  margin: -15px;
}
.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  display: none;
  margin: -50px 0 0 -50px;
  background: url(#{$global-img-path}play.png) no-repeat 0 0;
}
.fieldDescription {
  display: none;
}

/**
    GOOGLE SERP PREVIEW
 */
#google_serp_preview {
  max-width: 600px;
  width: 100%;
  * {
    font-family: arial, sans-serif;
  }
  h3 {
    color: #1a0dab;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }
  cite {
    color: #006621;
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
  }
  span {
    color: #545454;
    font-size: 13px;
    line-height: 18px;
    display: block;
  }
}


/**
    COMPONENTS
 */

.modal-xl {
  width: 90%;
}
.input-group {
  .form-control {
    z-index: 3;
  }
}
.modal-open {
  .bootstrap-select {
    .dropdown-menu {
      z-index: 20050 !important;
    }
  }
}
.modal-dialog {
  margin-top: 100px;
}

.timeline {
  &.no-line {
    &:before {
      background: none !important;
    }
  }
}


.mt-widget-4-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  right: 15px;
  padding: 0 15px;
  background: rgba(0,0,0,0.7);
}


.mt-element-overlay{

  .ribbon{
    position: absolute;
    margin-top: 0px !important;
  }

  .mt-overlay-6{
    background: none;

    .mt-overlay{
      padding: 0px 10px;
    }

    h1,h2,h3,h4,h5,h6{
      color: #fff;
      text-align: center;
      position: relative;
      overflow: hidden;
      padding: 0 0 5px 0;
      margin-bottom: 0;
      background-color: transparent;
      opacity: 0;
      filter: alpha(opacity=0);

    }

    h6{
      font-size: 10px;

      &:after{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #fff;
        content: '';
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0);
      }
    }

    &:hover {

      .mt-overlay{
        background-color: rgba(0,0,0,0.7);
      }

      h1,h2,h3,h4,h5,h6 {
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transition:all .2s ease-in-out;
        transition:all .2s ease-in-out;

        &:after{
          -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
        }

      }
    }

  }

}
@if $theme-type == "material-design" {
  .alert {
    overflow: hidden;
  }
}

/**
    ORDERS
 */
.note-small {
  padding: 5px;
  margin-bottom: 10px;
}
.note-small small {
  margin: -3px 0 0 0;
  border-radius: 2px;
}
.order_step:hover {
  cursor: pointer;
}

/**
    CALENDAR & AVAILABILITY
 */
.fc-day-time{
  padding: 5px 18px 0 5px;
  font-size: 12px;
  color: #FFF;
}
.fc-other-month{
  opacity: 0.2;
}
.shift_quantity {
  width: 80%;
  display:inline-block;
}
.btn-remove-condition {
  position: absolute !important;
  top: -15px;
  right: -20px;
}

.calendar-editable {

  .fc-day:hover {
    cursor: pointer;
    background-color: #cfb000 !important;
  }

}

/**
    PARTICIPANT LIST
 */
.participants {
  counter-reset: participant;

  .participant {
    counter-increment: participant;

    .participant_counter:before {
      content: counter(participant) ".";
    }
  }

}

/**
    VOUCHER RECIPIENT LIST
 */
.recipients {
  counter-reset: recipient;

  .recipient {
    counter-increment: recipient;

    .recipient_counter:before {
      content: counter(recipient) ".";
    }
  }

}

/**
    GRAFICI STATISTICHE
 */

.portlet-chart {
  .dashboard-stat2 {
    .number {
      h4 {
        i {
          width: 30px;
        }
      }
    }
  }
}
.amCharts-wrapper {
  overflow: visible !important;
}
.amcharts-chart-div {
  overflow: visible !important;
  > a{
    display: none !important;
  }
}
.amcharts-main-div {
  svg {
    overflow: visible !important;
  }
}