/***
Theme Panel
***/

.btn-theme-panel {
    margin-top: 25px; 

    .btn {
        @include opacity(0.6);
        padding: 0 6px;

        > i {
            font-size: 24px;
            color: darken(#CAD3DB, 10%); 
        }

        &:hover {
            @include opacity(1);
        }
    }    

    &.open .btn {
         @include opacity(1);
    }
}

.theme-panel {
    z-index: 1000;  
    min-width: 675px;  
    padding: 20px 10px;
    font-family: $font-family-primary;

    h3 {
        margin: 8px 0 8px 0;
        font-size: 15px;
        padding-left: 12px;
    }

    .seperator {
        border-left:1px solid #EFF2F4;
    }

    .theme-colors {
        list-style: none;
        padding: 0;
        margin: 0;

        > li.theme-color {
            padding: 8px 12px;

            &:hover,
            &.active {
                background: lighten(#EFF2F4, 2%); 
            }

            &:hover {
                cursor: pointer;
            }

            > .theme-color-view {
                float: left;
                margin-top: 0px;
                margin-right: 8px; 
                display: inline-block;
                border-radius: 10px !important;
                height: 20px;
                width: 20px;
            }

            > .theme-color-name {
                display: inline-block;
                color: #777;  
                font-size: 14px;
                font-weight: 300;
                padding-top: -4px; 
            }

            &.theme-color-default .theme-color-view {
                background: darken(#3fc9d5, 3%);
            }

            &.theme-color-red-sunglo .theme-color-view {
                background: #E26A6A;
            }

            &.theme-color-red-intense .theme-color-view {
                background: #E35B5A;
            }

            &.theme-color-blue-hoki .theme-color-view {
                background: #67809F;
            }

            &.theme-color-blue-steel .theme-color-view {
                background: #4B77BE;
            }

            &.theme-color-green-haze .theme-color-view {
                background: #44B6AE;
            }

            &.theme-color-purple-plum .theme-color-view {
                background: #8775A7;
            }

            &.theme-color-purple-studio .theme-color-view {
                background: #8E44AD;
            }            

            &.theme-color-yellow-orange .theme-color-view {
                background: #F2784B;
            }

            &.theme-color-yellow-crusta .theme-color-view {
                background: #F3C200;
            }
        }
    } 

    .theme-settings {
        list-style: none;
        padding: 0;
        margin: 0;

        > li {
            padding: 8px 12px;
            font-size: 14px;
            font-weight: 300;
            color: #777;  

            .form-control {  
                color: #777;  
                margin-top: -3px;
                float: right;
            }
        }
    }
}

@media (max-width: $screen-xs-max) { /* 767px */

    .theme-panel {
        left: 20px;
        right: 20px;
        min-width: 285px; 

        .seperator {
            border: 0;
        }

        .theme-settings {
            .form-control {  
                width: 105px !important;
            }
        }
    }

}