/***
AngularJS Basic Animations
***/

@-webkit-keyframes fadeInUp {
    0% {
	    opacity: 0;
	    -webkit-transform: translateY(15px);
	}
	100% {
	    opacity: 1;
	    -webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInUp {
    0% {
	    opacity: 0;
	    -moz-transform: translateY(15px);
	}
	100% {
	    opacity: 1;
	    -moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInUp {
    0% {
	    opacity: 0;
	    -o-transform: translateY(15px);
	}
	100% {
	    opacity: 1;
	    -o-transform: translateY(0);
	}
}

@keyframes fadeInUp {
    0% {
	    opacity: 0;
	    transform: translateY(15px);
	}
	100% {
	    opacity: 1;
	    transform: translateY(0);
	}
}

.fade-in-up {
    -webkit-animation: fadeInUp .5s;
    animation: fadeInUp .5s;
}  

@-webkit-keyframes bounceDelay {
  0%, 80%, 100% { -webkit-transform: scale(0.0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes bounceDelay {
  0%, 80%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 

  40% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

// Material Design Animations

// main: _material.scss

@keyframes input-focus {
    0% {
        left: 20%;
        width: 20%;
    }
    99% {
        width: 0;
        left: 0;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}